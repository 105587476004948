
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from './services/StorageServices';
import store from './store';

@Options({})
export default class App extends Vue {

    mounted(){
        let totalHeight = window.innerHeight;
        document.querySelector('html').style.setProperty('--totalheight', totalHeight + "px");

        // if(this.$route.name == 'domanda1' || this.$route.name == 'domanda2' || this.$route.name == 'domanda3'){
        //     store.state.timer = Math.round((Date.now() - store.state.lead.inizio) / 1000);
        //     store.state.timerInterval = setInterval(() => {
        //         store.state.timer++;
        //     }, 1000)
        //     store.state.showTimer = true;
        // }

        window.addEventListener('resize', () => {
            let totalHeight = window.innerHeight;
            document.querySelector('html').style.setProperty('--totalheight', totalHeight + "px");
        })
    }

}
