import { createStore } from 'vuex'
import * as OM from '@/model';
import { StorageServices } from '@/services/StorageServices';

let store = createStore({
    state: {
        lead: <OM.Lead>null,
        timer: 0,
        timerInterval: null,
        showTimer: false,
        rescaleImage: (img: HTMLImageElement, toWidth, toHeight) => {
            console.log(img.height);
            img.height = img.getBoundingClientRect().height * toHeight / 1920;
            img.width = img.getBoundingClientRect().width * toWidth / 1080;
        }
    },
})

store.state.lead = StorageServices.getlead();

export default store;