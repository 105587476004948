import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/partiamo',
        name: 'root',
    },
    {
        path: '/partiamo',
        name: 'partiamo',
        component: () => import('../views/partiamo.vue')
    },
    {
        path: '/versione',
        name: 'versione',
        component: () => import('../views/versione.vue')
    },
    {
        path: '/autentica',
        name: 'autentica',
        component: () => import('../views/autentica.vue')
    },
    {
        path: '/innovativa',
        name: 'innovativa',
        component: () => import('../views/innovativa.vue')
    },
    {
        path: '/moderna',
        name: 'moderna',
        component: () => import('../views/moderna.vue')
    },
    {
        path: '/grazie',
        name: 'grazie',
        component: () => import('../views/grazie.vue')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
