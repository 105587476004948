import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css'
import '@/css/style.css'

import '@/dragdroptouch';

import { CommonServices } from '@/services/CommonServices';

CommonServices.unauthorizedCb = () => {
}
CommonServices.showSpinnerCb = () => {
    store.commit("showSpinner");
}
CommonServices.hideSpinnerCb = () => {
    store.commit("hideSpinner");   
}

createApp(App).use(store).use(router).mount('#app')
